.loaderP {
   width: 16px;
   height: 16px;
   border-radius: 50%;
   background-color: rgb(112, 145, 200);
   box-shadow:
      32px 0 rgb(112, 145, 200),
      -32px 0 rgb(112, 145, 200);
   position: relative;
   animation: flash 1s ease-out infinite alternate;
}

@keyframes flash {
   0% {
      background-color: #fff2;
      box-shadow:
         32px 0 #fff2,
         -32px 0 rgb(112, 145, 200);
   }

   50% {
      background-color: rgb(112, 145, 200);
      box-shadow:
         32px 0 #fff2,
         -32px 0 #fff2;
   }

   100% {
      background-color: #fff2;
      box-shadow:
         32px 0 rgb(112, 145, 200),
         -32px 0 #fff2;
   }
}
