.loader {
   position: relative;
   /* display: inline-block; */
}

.loader:after {
   align-self: flex-start;
   content: '';
   height: 4px;
   width: 0%;
   display: block;
   background-color: rgba(26, 64, 254, 0.834);
   animation: 10s lineGrow linear;
   animation-iteration-count: 1;
}

@keyframes lineGrow {
   to {
      width: 100%;
   }
}

.loaderz {
   width: 100%;
   height: 4px;
   display: inline-block;
   background-color: #fff;
   background-image: linear-gradient(45deg, rgba(26, 64, 254, 0.834) 25%, transparent 25%, transparent 50%, rgba(26, 64, 254, 0.834) 50%, rgba(26, 64, 254, 0.834) 75%, transparent 75%, transparent);
   font-size: 30px;
   background-size: 1em 1em;
   box-sizing: border-box;
   animation: barStripe 1s linear infinite;
}

@keyframes barStripe {
   0% {
      background-position: 1em 0;
   }

   100% {
      background-position: 0 0;
   }
}