/* @import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap'); */

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Urbanist', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.urbanist {
   font-family: "Urbanist", sans-serif;
}

.flex-center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.text-overflow {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.h-100dvh {
   height: 100dvh;
}

.h-100svh {
   height: 100svh;
}

.w-100dvh {
   width: 100dvw;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}

@tailwind base;
@tailwind components;
@tailwind utilities;